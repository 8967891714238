<template>
  <div class="container">
    <div class="main">
      <div @click="handleBack" class="flexSt headerbox">
        <img src="../../assets//img/126.png" alt="" />
        {{ Info.name }}
      </div>
      <div class="flexSb">
        <div class="left">
          <div class="title">排名</div>
          <el-table
            highlight-current-row
            @current-change="handlestudentpoints"
            :data="tableData"
            style="width: 100%"
            ref="singleTable">
            <el-table-column
              prop="group_no"
              label="小组"
              width="80"
              align="center">
            </el-table-column>
            <el-table-column prop="student_name" label="姓名" align="center">
            </el-table-column>
            <el-table-column
              prop="specs"
              label="编号"
              width="80"
              align="center">
            </el-table-column>
            <el-table-column label="进度" width="160" align="center">
              <template slot-scope="scope">
                <div class="tip_gro">
                  <div class="flexSb">
                    <div>进度</div>
                    <div>
                      {{ scope.row.point_index }}:{{ scope.row.point_count }}
                    </div>
                  </div>
                  <el-progress
                    :show-text="false"
                    :stroke-width="10"
                    :percentage="scope.row.progress"></el-progress>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="heart"
              label="心率"
              width="80"
              align="center">
            </el-table-column
            ><el-table-column
              prop="rank"
              label="排行"
              width="80"
              align="center">
            </el-table-column>
          </el-table>
        </div>

        <div class="right">
          <div class="title">实时轨迹图</div>
          <div class="student flexSb">
            <div>
              第{{ currentInfo.group_no ? currentInfo.group_no : "--" }}组
            </div>
            <div>
              {{ currentInfo.student_name ? currentInfo.student_name : "--" }}
            </div>
            <div>编号：{{ currentInfo.specs ? currentInfo.specs : "--" }}</div>
            <div class="gre">
              <div class="tip_gro">
                <div class="flexSb">
                  <div>进度</div>
                  <div>
                    {{
                      currentInfo.point_index ? currentInfo.point_index : 0
                    }}:{{
                      currentInfo.point_count ? currentInfo.point_count : 0
                    }}
                  </div>
                </div>
                <el-progress
                  :show-text="false"
                  :stroke-width="10"
                  :percentage="
                    currentInfo.progress ? currentInfo.progress : 0
                  "></el-progress>
              </div>
            </div>
            <div>心率：{{ currentInfo.heart ? currentInfo.heart : "--" }}</div>
            <div>体温：{{ currentInfo.temp ? currentInfo.temp : "--" }}</div>
            <div>排行：{{ currentInfo.rank ? currentInfo.rank : "--" }}</div>
          </div>
          <div id="mapContainerTD" class="mapContainerTD"></div>
          <div class="scro_list">
            <maoScroll
              :data="datalist"
              :showNum="showNum"
              :animationScroll="animationScroll"
              :animation="animation">
            </maoScroll>
          </div>
        </div>
      </div>

      <div class="flexSc djs">
        <div class="tit">倒计时</div>
        <uCountDown
          :timestamp="endTime"
          format="HH:mm:ss"
          :fontSize="18"></uCountDown>
        <el-button class="btn" @click="open" type="warning" round
          >结束赛事</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import maoScroll from "../components/mao-scroll/mao-scroll.vue";
import uCountDown from "../components/timeDown/index.vue";
import { getSchoolID, getUser } from "@/utils/auth";
import {
  distanceRank,
  gatewaypointsMapids_school,
  studentpoints,
  realTimePoint,
  Endorienteer,
  gatewaygpsInfo,
} from "@/api/games/index";
export default {
  components: { maoScroll, uCountDown },
  data() {
    return {
      gpsPoint: [],
      datalist: [],
      count: 10,
      showNum: 5,
      // lineHeight: 20,
      animationScroll: 800,
      animation: 2000,
      screenHeight: 0,
      studentLines: [],
      studentPoints: [],
      tableData: [],
      isFirst: true,
      Info: {
        name: "",
        id: "",
      },
      pageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      marketList: [],
      marginnum: 0,
      pointJson: [],
      currentLocation: {},
      currentInfo: {
        rank: "",
        group_no: "",
        student_name: "",
        specs: "",
        heart: "",
        rank: "",
        point_index: "",
        point_count: "",
        progress: "",
      },
      day: Number(new Date()),
      time: 0,
      endTime: null,
    };
  },
  created() {
    if (this.$route.query.val) {
      this.Info = JSON.parse(this.$route.query.val);
      console.log(this.Info, "=this.Info ");
      this.endTime = parseInt(
        new Date(this.Info.end_time).getTime() / 1000,
        10
      );
    }
    //   lng, lat
  },
  mounted() {
    this.$nextTick(() => {
      this.handlesettime();
      this.handlepaceRank();
      this.hanleGetdistanceRank();
      this.initwxMap(this.Info.center_lng, this.Info.center_lat);
      this.handleGetgatewaygpsInfo();
      this.handlegatewaypointsMap();
    });
  },
  destroyed() {
    clearInterval(this.timese);
  },
  methods: {
    handleGetgatewaygpsInfo() {
      let data = {
        id: this.Info.id,
      };
      gatewaygpsInfo(data).then((res) => {
        console.log(res.data);
        this.gpsPoint = res.data;
      });
    },

    open() {
      this.$confirm("本次赛事时间已到达，请确认结束赛事?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleEndorienteer();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    }, // 结束赛事
    handleEndorienteer() {
      let data = {
        id: this.Info.id,
      };
      Endorienteer(data).then((res) => {
        clearInterval(this.timese);
        this.$router.push("/realTimeScore?val=" + JSON.stringify(this.Info));
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
    handlesettime() {
      this.timese = setInterval(() => {
        this.hanleGetdistanceRank();
        this.handlepaceRank();
        this.hanlestudentGetdistanceRank(this.currentStudengt.student_id);
        this.handlestudentpoints(this.currentStudengt.student_id);
      }, 5000);
    },
    //实时打卡
    handlepaceRank() {
      let data = {
        id: this.Info.id,
      };

      realTimePoint(data).then((res) => {
        let val = res.data;
        console.log(val, 122);
        if (val) {
          if (val.length > this.datalist.length) {
            this.datalist = val;
            console.log(this.datalist, "==datalist");
          }
        }
      });
    },
    // 单个学生
    handlestudentpoints(row) {
      if (row) {
        console.log(row, "=row");
        this.currentInfo = row;
        let data = {
          studentId: row.student_id,
          id: this.Info.id,
        };
        studentpoints(data).then((res) => {
          this.studengpointlist = res.data.studentPoints;
          this.hanleShowPolne(this.studengpointlist);
        });
      }
    },
    // 单个学生轨迹

    hanleShowPolne(data) {
      if (
        this.studentPoints &&
        this.studentLines.length > 0 &&
        this.studentPoints.length > 0
      ) {
        this.studentPoints.forEach((item) => {
          map.removeOverLay(item);
        });
        map.removeOverLay(studentLines);
        this.studentLines = null;
        this.studentPoints = [];
      }
      // if(line){
      // 	map.removeOverLay(line)
      // 	map.removeOverLay(markerpoi);
      // }
      let data2 = data;
      let points = [];
      let line = null;
      data2.forEach((pointItem) => {
        line = new T.LngLat(pointItem.lng, pointItem.lat);
        points.push(line);
      });
      line = new T.Polyline(points, {
        color: "#537AF2",
        weight: 3,
        opacity: 1,
      });

      //向地图上添加线
      this.wxMap.addOverLay(line);
      this.studentLines = line;
      let incon1;
      points.forEach((item, index) => {
        if (index == 0) {
          incon1 =
            "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/dxyy/start.png";
        } else if (index == points.length - 1) {
          incon1 =
            "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/dxyy/student.png";
        } else {
          incon1 =
            "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/dxyy/point.png";
        }
        let markerpoi = new T.Marker(new T.LngLat(item.lng, item.lat), {
          icon: new T.Icon({
            iconUrl: incon1,
            iconSize: new T.Point(30, 30),
          }),
        });
        //向地图上添加标注
        this.wxMap.addOverLay(markerpoi);
        this.studentPoints.push(markerpoi);
      });
    },
    // 校内跑添加
    async handlegatewaypointsMap() {
      let userinfo = getUser();
      let data = {
        school_id: userinfo.school_id,
        id: this.Info.id,
      };

      await gatewaypointsMapids_school(data).then((res) => {
        let data_l = {};
        if (res.data.school_map) {
          let val = res.data.school_map;
          data_l = {
            lat: val.center_lat,
            lng: val.center_lng,
            school_name: val.school_name,
            left_up_lng: val.left_up_lng,
            left_up_lat: val.left_up_lat,
            right_down_lng: val.right_down_lng,
            right_down_lat: val.right_down_lat,
            map_overlay_url: val.map_overlay_url,
            default_points: res.data.default_points,
            gateway_count: res.data.gateway_count,
          };
        } else {
          data_l = {
            lat: res.data.default_points[0].lat,
            lng: res.data.default_points[0].lng,
          };
        }
        this.pointJson = res.data.default_points;
        this.currentLocation = data_l;

        //   添加校园图片
        if (data_l.left_up_lng) {
          //校内跑新增的。。。
          var bd = new T.LngLatBounds(
            new T.LngLat(data_l.left_up_lng, data_l.left_up_lat),
            new T.LngLat(data_l.right_down_lng, data_l.right_down_lat)
          );
          var img = new T.ImageOverlay(data_l.map_overlay_url, bd, {
            opacity: 1,
            alt: data_l.school_name,
          });

          this.wxMap.addOverLay(img);
          this.wxMap.setMinZoom(3);
          this.wxMap.setMaxZoom(24);
        }
        //添加默认点
        if (this.pointJson.length != 0) {
          this.pointJson.forEach((m, n) => {
            this.gpsPoint.forEach((item, index) => {
              if (item.point_index == m.point_index) {
                console.log(item.point_index, m.point_index, "===========");
                m.dkq = true;
                m.lat = item.lat;
                m.lng = item.lng;
              }
            });
            if (n == this.pointJson.length - 1) {
              this.handleAdddefaultPoint(this.pointJson);
            }
          });
        }
      });
    },
    handleAdddefaultPoint(marketList) {
      marketList.forEach((item, index) => {
        var marker = new T.Marker(new T.LngLat(item.lng, item.lat), {
          icon: new T.Icon({
            iconUrl: item.dkq
              ? "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/Group%2053%402x.png"
              : "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/Group%2054%402x.png",
            iconSize: new T.Point(30, 30),
          }),
        });
        //向地图上添加标注
        this.wxMap.addOverLay(marker);

        this.handleName(item.name, item.lng, item.lat);
      });
    },

    //   标记点命名
    handleName(e, currentMarkLng, currentMarkLat) {
      var latlng = new T.LngLat(currentMarkLng, currentMarkLat);
      var label = new T.Label({
        text: e,
        position: latlng,
        offset: new T.Point(-10, 0),
      });

      label.setFontColor("#FF7E64");
      label.setBackgroundColor("rgba(255, 255, 255, .76)");
      label.setBorderColor("transparent");

      // 关闭信息窗口
      this.wxMap.closeInfoWindow();
      //创建地图文本对象
      this.wxMap.addOverLay(label);
      // alert(e)
    },
    // 获取公里数排名
    hanleGetdistanceRank() {
      // this.isLoading = true
      let data = {
        id: this.Info.id,
        per_page: this.pageInfo.pageSize,
        page: this.pageInfo.pageNum,
      };
      distanceRank(data).then((res) => {
        this.tableData = res.data.data;
        this.pageInfo.total = res.data.total;
        if (this.tableData.length != 0) {
          this.currentInfo = this.tableData[0];
          this.handlestudentpoints(this.tableData[0]);
          this.$nextTick(() => {
            this.$refs.singleTable.setCurrentRow(this.tableData[0]);
          });
        }
      });
    },

    handleBack() {
      this.$router.go(-1);
    },
    initwxMap(lng, lat) {
      // let T = window.T
      if (this.isFirst) {
        this.wxMap = new T.Map("mapContainerTD");
        this.wxMap.centerAndZoom(new T.LngLat(lng, lat), 18);
      }
      this.isFirst = false;
      this.handleAddmaeck();
    },

    handleAddmaeck(val) {
      // 删除也有标记

      if (this.marginnum <= this.marketList.length - 1) {
        var newMarker = this.wxMap.getOverlays(); // 获取到了地图上的所有点
        for (var n = 0; n < newMarker.length; n++) {
          // 遍历地图上点
          // if (data == newMarker[n].id) { // 判断
          // 执行删除方法
          this.wxMap.removeOverLay(newMarker[n]);
          // }
        }

        let that = this;
        var points = [];
        if (this.marketList.length > 0) {
          this.marketList.forEach((item, index) => {
            if (item.lng) {
              if (this.marginnum <= this.marketList.length - 1) {
                if (index <= this.marginnum) {
                  points.push(
                    new T.LngLat(
                      Number(item.lng) + that.pointnum,
                      Number(item.lat) + that.pointnum
                    )
                  );
                }
              }

              var line = new T.Polyline(points);
              this.wxMap.addOverLay(line);
            }
          });

          let info_mark = this.marketList[that.marginnum];
          var marker = new T.Marker(
            new T.LngLat(
              Number(info_mark.lng) + that.pointnum,
              Number(info_mark.lat) + that.pointnum
            ),
            {
              icon: new T.Icon({
                iconUrl:
                  "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/dxyy/0143.png",
                iconSize: new T.Point(30, 30),
              }),
            }
          );

          marker.addEventListener("click", function (e) {
            var m = that.marketList.findIndex((item) => {
              return item.lat == e.lnglat.lat;
            });
            console.log(m);
            let lng = that.marketList[m].lng;
            let lat = that.marketList[m].lat;
            that.currentStudent = that.marketList[m];
            let val = that.currentStudent.used_time;
            if (val && val > 0) {
              that.currentStudent.time_t = that.handledateFormatSend(val);
            } else {
              that.currentStudent.time_t = "00:00:00";
            }
          });
          // 将标注添加到地图中

          this.wxMap.addOverLay(marker);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  box-sizing: border-box;
  //   height: 98%;
  flex: 1;

  .headerbox {
    margin-bottom: 20px;
    img {
      width: 23px;
      height: 23px;
      margin-right: 15px;
    }
    font-size: 24px;
    cursor: pointer;
  }
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 0.05rem;
  padding: 0.4375rem 0.3125rem;
  height: 100%;
  .left {
    height: 700px;
    border: 1px solid #baccfb;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    padding: 20px;
    width: 40%;
    .title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    ::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      // background-color: #ccc !important;
    }
    ::-webkit-scrollbar-track {
      // background-color: #bbbbbb;
    }
    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
    /deep/.cell {
      font-size: 18px;
    }
    /deep/.tip_gro {
      font-size: 14px;
    }
  }
  .right {
    width: 59%;
    height: 700px;
    border: 1px solid #baccfb;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    padding: 20px;
    position: relative;

    .scro_list {
      position: absolute;
      bottom: 20px;
      right: 50px;
      z-index: 99999;
      width: 350px;
      height: 200px;
      // background-color: pink;
    }
    .title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .infolist {
      background: #edf1fe;
      box-shadow: inset 0px -1px 0px 1px #e9ebf2;

      text-align: center;
      padding: 10px 8px;
      box-sizing: border-box;

      .num {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .student {
      font-size: 16px;
      background: #425e8f;
      border-radius: 11px;
      padding: 15px;
      box-sizing: border-box;
      color: #fff;
      .gre {
        width: 180px;
      }
    }
  }
  .mapContainerTD {
    width: 100%;
    height: 80%;
    margin-top: 20px;
  }
  .djs {
    font-size: 18px;
    margin-top: 15px;
    .tit {
      color: #ff7e64;
      margin-right: 15px;
    }
    /deep/.uni-countdown {
      font-size: 18px;
    }
    .btn {
      margin-left: 15px;
      width: 240px;
    }
  }
}
</style>
