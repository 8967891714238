<template>
  <div>
    <div
      class="maoScroll-main"
      :style="'height:' + lineHeight * showLine + 'px;'">
      <div :style="'margin-top:-' + marginTop + 'px;'">
        <div
          v-for="(item, index) in showdata"
          :key="'maoScroll' + index"
          :style="'height:' + lineHeight + 'px;'"
          class="mt">
          <!-- <slot :line="item" /> -->
          <div class="line_list flexSb">
            <span class="line_title">第{{ item.group_no }}组</span>
            <span class="line_title">{{ item.student_name }}</span>
            <span class="line_title">{{ item.point_index }}号打卡点</span>
            <span class="line_title" style="color: #6db5ff">{{
              item.time
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "maoScroll",
  data() {
    return {
      showdata: [],
      marginTop: 0,
      showLine: 0,
      timer: null,
      step: 0,
      stepTime: 100,
    };
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
    showNum: {
      type: Number,
      default: 3,
    },
    lineHeight: {
      type: Number,
      default: 40,
    },
    animationScroll: {
      type: Number,
      default: 500,
    },
    animation: {
      type: Number,
      default: 2000,
    },
  },
  methods: {
    init: function () {
      this.showLine =
        this.showNum < this.data.length ? this.showNum : this.data.length;
      console.log(this.showLine, "==showLine");
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.showdata = this.data;
      this.animationFunc();
      console.log(this.showdata, "-showdata");
    },
    animationFunc: function () {
      this.stepTime = 100;
      this.marginTop = 10;
      this.step = 0;
      let self = this;
      this.timer = setInterval(function () {
        self.marginTop = self.marginTop + 1;
        if (self.data && self.data.length) {
          if (self.marginTop >= self.data.length * self.lineHeight) {
            self.marginTop = 0;
            self.step = 0;
          }
        }
        // console.log(self.marginTop)
      }, this.stepTime);
    },
  },
  watch: {
    data(outdata, newdata) {
      this.init();
    },
  },
};
</script>

<style>
.line_list {
  /* width: 290px; */
  height: 59px;
  background: rgba(34, 55, 102, 0.73);
  box-shadow: 4px 16px 48px 0px rgba(111, 143, 234, 0.1);
  border-radius: 30px;
}

.mt {
  margin-top: 5px;
}
.line_title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.line_list {
  /* width: 290px; */
  height: 49px;
  background: rgba(34, 55, 102, 0.73);
  box-shadow: 4px 16px 48px 0px rgba(111, 143, 234, 0.1);
  border-radius: 30px;
  padding: 0 10px;
  margin-bottom: 15px;
}
.flexSb {
  flex-direction: row;
}
.maoScroll-main {
  width: 290px;
  overflow: hidden;
}
</style>
