import request from "@/utils/request";
import { baseUrl } from "@/utils/config";

//课堂数据
export function orienteer(data) {
  return request({
    url: baseUrl + "/orienteer/running/list",
    method: "get",
    params: data,
  });
}
// 列表数据
export function orienteernew(data) {
  return request({
    url: baseUrl + "/orienteer",
    method: "get",
    params: data,
  });
}

export function gatewaypointsMapids(data) {
  return request({
    url: baseUrl + "/course",
    method: "get",
    params: data,
  });
}

// 公里数排名
export function distanceRank(data) {
  return request({
    url: baseUrl + "/orienteer/rank/distanceRank",
    method: "get",
    params: data,
  });
}

// 学校默认打点-地图-网关数量
export function gatewaypointsMapids_school(data) {
  return request({
    url:
      "/orienteer/gateway/pointsMap?school_id=" +
      data.school_id +
      "&id=" +
      data.id,
    method: "get",
  });
}

// 单个轨迹图 /api/orienteer/student/points
export function studentpoints(data) {
  return request({
    url: "/orienteer/student/points",
    method: "get",
    params: data,
  });
}
// 实时打卡数据
export function realTimePoint(data) {
  return request({
    url: "/orienteer/rank/realTimePoint?id=" + data.id,
    method: "get",
  });
}
// 结束单个赛事
export function Endorienteer(data) {
  return request({
    url: "/orienteer/end/" + data.id,
    method: "put",
    data: data,
  });
}

// 获取打卡器gps坐标
export function gatewaygpsInfo(data) {
  return request({
    url: "/orienteer/gateway/gpsInfo",
    method: "get",
    params: data,
  });
}
// 已结束查看总成绩
export function historyRank(data) {
  return request({
    url: "/orienteer/rank/historyRankForPerson",
    method: "get",
    params: data,
  });
}
